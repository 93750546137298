import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles,makeStyles } from '@material-ui/styles';
import HelpIcon from '@material-ui/icons/Help';
import {
  IconButton
  } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
export default function Helptip(props) {
  return (
    <>
    {props.title}
   <LightTooltip arrow  placement="right" title={props.helpinfo}>
   <IconButton aria-label="delete">
    <HelpIcon fontSize="small" />
   </IconButton>
 </LightTooltip>
 </>
  );
}