import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  uo: {
    fontSize: 16,
  },
  middleDiv: {
    marginTop: "1%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1%",
  },
  Title: {
    padding: "14px 6px",
    border: "1px solid #000",
    width: 380,
    borderRadius: 4,
  },
}));

export function DocTitle(props) {
  const classes = useStyles();
  return (
    <div className={classes.middleDiv}>
      <div className={classes.uo}>
        <Typography className={classes.Title}>
        விஸ்வகர்ம சேவார்த்திகள் பதிவு 
        </Typography>
      </div>
    </div>
  );
}
