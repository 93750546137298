import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
 import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Typography,
  Avatar,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  TableRow,
  colors
} from '@material-ui/core';

import { Label,  TableEditBar,GenericMoreButton } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  nodataCont: {
    textAlign: "center",
    padding: "14px",
    fontSize: "18px",
    color: "#bfbfbf",
    fontWeight: 800
},
  inner: {
    minWidth: 1150
  },
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const { className,totalcount, orders,years,selectyear, ...rest } = props;

  const classes = useStyles();

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [value, setValue] = React.useState(0);

  const handleSelectAll = event => {
    const selectedOrders = event.target.checked
      ? orders.map(order => order.id)
      : [];

    setSelectedOrders(selectedOrders);
  };
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }

    setSelectedOrders(newSelectedOrders);
  };

  const handleChange = (event, newValue) => {
	//props.onyearchange(value)
	setValue(newValue);
	props.onyearchange(newValue)
   
  };
  const handleChangePage = (event, page) => {
    setPage(page);
    props.onpagechange(page)
  };
  const handleoptionchange = type => (event, value) => {
    
   
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const paymentStatusColors = {
    canceled: colors.grey[600],
    pending: colors.orange[600],
    active: colors.green[600],
    inactive: colors.red[600]
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
     
      <Card>
        <CardHeader
          
          title="User List"
		  action={
			// <Autocomplete
			// id="combo-box-demo"
			// options={years}
			// value={selectyear}
			// style={{ width: 150 }}
			// onChange={handleoptionchange('year')}

			// renderInput={(params) => <TextField {...params} label="Choose Year" margin='dense' variant="outlined" />}
			// />
			<Tabs value={selectyear} onChange={handleChange} aria-label="simple tabs example">
				{
					years.map((year, index) => (
						<Tab label={year} value={year}  />

					))
				}
			
			</Tabs>
		  }
        />
        <Divider />
        <CardContent className={classes.content}>
            <div className={classes.inner}>
            <TableContainer className={classes.container}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedOrders.length === orders.length}
                        color="primary"
                        indeterminate={
                          selectedOrders.length > 0 &&
                          selectedOrders.length < orders.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell> */}
                    <TableCell>S no</TableCell>
                    <TableCell align="center">Registration No</TableCell>
                    <TableCell >Photo</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Fathers Name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    
                    <TableCell align="center">Mobile/Phone</TableCell>
                    <TableCell align="center">DOB</TableCell>
                    <TableCell align="center">Area</TableCell>
					<TableCell align="center">Last Renewed</TableCell>
                    <TableCell align="center">CreatedAt</TableCell>

                    <TableCell align="center">CreatedBy</TableCell>

                    
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order, index) => (
                    <TableRow
                      key={order.id}
                      selected={selectedOrders.indexOf(order.id) !== -1}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedOrders.indexOf(order.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, order.id)}
                          value={selectedOrders.indexOf(order.id) !== -1}
                        />
                      </TableCell> */}
                      <TableCell>
                        {(page * rowsPerPage)+ (index+1)}
                        
                      </TableCell>

                      <TableCell align="center">   
                      {moment(order ?  order.createdAt : order.createdAt).format(
                            'YYYY' 
                          )}-{pad(order ?  order.register_id : order.Member.id,4)}                     
                      </TableCell>
                      <TableCell >
                        <Avatar align="center" variant="square" alt={order?.Member?.name} src={order?.Member?.profile_pic} />
                      </TableCell>
                      <TableCell align="center">{order?.Member?.Name}</TableCell>
                      <TableCell align="center">{order?.Member?.fatherName}</TableCell>
                      <TableCell align="center">{order?.Member?.email}</TableCell>
                      
                      <TableCell align="center">{order?.Member?.mobile}</TableCell>
                      <TableCell align="center">
                      {moment(order?.Member?.dob).format(
                            'DD MMM YYYY' 
                          )}
                      </TableCell>
                      <TableCell align="center">{order?.Member?.area}</TableCell>

                   
                     

                    

                      {/* <TableCell>
                         <Label
                          color={paymentStatusColors[order.isactive ? 'active' : 'inactive']}
                          variant="contained"
                        >
                          {order.isactive ? 'Active' : 'In Active'}
                        </Label>

                      </TableCell> */}
					    <TableCell> 
                     
					 <Typography variant="body1">
					 {moment(order.Member ?  order?.Member?.createdAt : order.createdAt).format(
                            'DD MMM YYYY' 
                          )}
					   </Typography>
					 </TableCell>
					 <TableCell> 
                     
					 <Typography variant="body1">
						 {moment(order.createdAt).format(
						   'DD MMM YYYY' 
						 )}
					   </Typography>
					 </TableCell>
                        <TableCell> 
                      <Typography variant="body1">
                         {order.Member.user_profile ? order.Member?.user_profile?.name : ''}
                        </Typography>
                        </TableCell>
                    
                            
                      <TableCell align="center">
                        {/* <GenericMoreButton /> */}
                        <Button size="small"
                        component={RouterLink}
                        to={{ 
                          pathname: "/viewuser", 
                          state:
                          {
                            reg_id : order.Member.id,
							            isedit : props.isadmin ? true : false
                          }
                         }}
                        
                        color={'primary'}
                        >
                        <VisibilityIcon  />

                        </Button>
                      </TableCell>
                      {/* <TableCell align="center">
                        <Button size="small"
                        color={'primary'}
                        variant="contained"
                        onClick={() => props.onVerify()}
                        >
                         Verify1

                        </Button>
                      </TableCell> */}
                   {(props.isadmin  && (moment(order.Member_Registrations ?  order.Member_Registrations[order.Member_Registrations.length - 1].createdAt : order.createdAt).format(
								'YYYY' 
							  ) == moment(new Date()).format('YYYY')) || (props.issearch == true && props.isadmin)   ) &&  <TableCell align="center">
                        <Button size="small" component={RouterLink}
                        to={{ 
                          pathname: "/edituser", 
                          state: {
                            id : order.Member.id,
                            isedit: true
                          }
                         }}
                        
                        color={'primary'}
                        >
                         <EditIcon />

                        </Button>
                      </TableCell>}     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {orders.length > 0 ? "" : <Typography className={classes.nodataCont} component={"h6"} >No Records found</Typography>}
               </TableContainer>


            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalcount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10]}
          />
        </CardActions>
      </Card>
      <TableEditBar selected={selectedOrders} />
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

Results.defaultProps = {
  orders: []
};

export default Results;
