import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import uuid from 'uuid/v1';
import moment from 'moment';
import  Page  from '../../components/Page';
import {  ProductFilter } from '../../components';
import { NetworkContext } from '../../context/NetworkContext';
import axios from 'axios';
import { Header, Results, Fingerverify } from './components';
import { fil, tr } from 'date-fns/locale';
var XMLParser = require('react-xml-parser');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));
export const Products = withRouter(props => {

  const classes = useStyles();
  const [products, setProducts] = useState([
 
  ]);
  const [downloadMembers, setDownloadMembers] = useState([
 
  ]);
  const [membercount, setMembercount] = useState(0);
  const [allyears, setAllyears] = useState([
 
]);

const [selyear,setSelyear] = useState("")
  const { sendNetworkRequest } = React.useContext(NetworkContext);
  const [masters, setMasters] = useState({});
  var OldPort=false;
  const [showverify, setShowverify] = useState(false);
  const [isadmin, setIsadmin] = useState(false);
  const [issearch, setIssearch] = useState(false);
  const [allowadd, setIsallowadd] = useState(true);
  const [isdownload, setIsdownload] = useState(false);


  
  const [filterparams, setFilterparams] = useState({});
  function  applysearch(searchtext)
  {
	  
    fetchmembers(searchtext,filterparams)
  }
  function  applyfilter(filtercontent)
  {

    setFilterparams({
      ...filtercontent
    })
    fetchmembers("",filtercontent)
  }
  function  onpagechange(page)
  {
    
    fetchcurrentyearmembers("",filterparams,"",page)
  }
  async function fetchallmembers()
  {
    var yearselected = localStorage.getItem('Year');
    if(!yearselected )
    {
      yearselected = getyears()[0]
    }
      let bodycontent = {
          area : filterparams ? filterparams.area : "",
          pincode : filterparams ?filterparams.pincode :"",
          searchtxt : "",
          year : yearselected ? yearselected : selyear,
          page : 0
      }
    
      let memberlists = await sendNetworkRequest('/getdownloadmembers', {}, bodycontent,true)
      setDownloadMembers([])
      setDownloadMembers(memberlists)
    setIsdownload(true)
   // setProducts(members)
  }
  function filterbyyear(year)
  {
    setDownloadMembers([])
    setIsdownload(false)
	  setIssearch(false)
	localStorage.setItem('Year', year);
		setSelyear(year)
		fetchcurrentyearmembers('','',year)
  }
  
  async function fetchcurrentyearmembers(searchtxt,filterparams,year,page)
  {
	var yearselected = localStorage.getItem('Year');
  if(!yearselected )
  {
    yearselected = getyears()[0]
  }
    let bodycontent = {
        area : filterparams ? filterparams.area : "",
        pincode : filterparams ?filterparams.pincode :"",
        searchtxt : searchtxt,
		    year : yearselected ? yearselected : selyear,
        page : page ? page : 0
    }
  
    let memberlists = await sendNetworkRequest('/getmembers', {}, bodycontent,true)
    setProducts([])
    setProducts(memberlists.members)
    setMembercount(memberlists.totalcount)
  }
  async function fetchmembers(searchtxt,filterparams,year)
  {
	if(searchtxt && searchtxt.length > 0 )
	{
		setIssearch(true)
	}else{
		setIssearch(false)
	}
    let bodycontent = {
        area : filterparams ? filterparams.area : "",
        pincode : filterparams ? filterparams.pincode :"",
        searchtxt : searchtxt,
        page :  filterparams ? filterparams.page :0,
   
    }
  
    let memberlists = await sendNetworkRequest('/getmembers', {}, bodycontent,true)
    setProducts([])
    setProducts(memberlists.members)
  }

  function getyears()
  {
		var max = new Date().getFullYear()
		var startyear = 2020

		var min = max - startyear
		var years = []
		years.push(""+max)
		if(min > 5)
		{
			min = 5;
		}
		for (var i = 1; i < min; i++) {
		  years.push(""+(max - i))
		}

		return years
	  
	  
  }
  function verifyfp()
  {
   
    setShowverify(true)
  }
  async function discoverdevice()
  {
      var SuccessFlag=0;
            var primaryUrl = "http://127.0.0.1:";

						 try {
							 var protocol = window.location.href;
							 if (protocol.indexOf("https") >= 0) {
								primaryUrl = "https://127.0.0.1:";
							}
						 } catch (e)
						{ }


           var url = "";
           for (var i = 11100; i <= 11120; i++)
                {
                  if(primaryUrl=="https://127.0.0.1:" && OldPort==true)
                  {
                    i="8005";
                  }
                }

           
                let response = await   axios({
                  method: 'RDSERVICE', //you can set what request you want to be
                  url:  primaryUrl +"11100",
                  data: {id: 1},
                  headers: {
                    'content-type':  "text/xml; charset=utf-8"
                  }
                })
               // var xml = new XMLParser().parseFromString(response);    // Assume xmlText contains the example XML
               alert(response.toString())
               // alert(xml)
               // console.log(xml.getElementsByTagName('Name'));
                
                let inforesponse = await   axios({
                  method: 'DEVICEINFO', //you can set what request you want to be
                  url:  primaryUrl +"11100/rd/info",
                  data: {id: 1},
                  headers: {
                    'content-type':  "text/xml; charset=utf-8"
                  }
                })

               


                let captureresponse = await   axios({
                  method: 'CAPTURE', //you can set what request you want to be
                  url:  primaryUrl +"11100/rd/capture",
                  data: `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="0" iCount="0" pCount="0" format="0"   pidVer="2.0" timeout="10000" posh="UNKNOWN" env="PP" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>`,
                  headers: {
                    'content-type':  "text/xml; charset=utf-8"
                  }
                })

            alert(captureresponse);

  }
  async function checkisadmin()

  {
    let products = await sendNetworkRequest('/checkisadmin', {}, {},true)
    setIsadmin(products.isaccess)

  }
  async function checkallowadd()

  {
    let isallowed = await sendNetworkRequest('/checkmemberaddition', {}, {},true)

    setIsallowadd(isallowed.allow)

  }
  useEffect(() => {
   // discoverdevice()
   checkallowadd()
   checkisadmin()
   fetchcurrentyearmembers('')
  setAllyears(getyears())
  var yearselected = localStorage.getItem('Year');
    if(!yearselected)
    {
      yearselected = getyears()[0]
    }
	setSelyear(yearselected)
    // fetchallproducts(filterparams)
  }, []);

  return (
    <Page
      className={classes.root}
      title="Commodity"
    >
      <Header />
       <ProductFilter  ilter  masters={masters} isadduser={allowadd}   userslist={downloadMembers} onSearch={applysearch} isDownloadready={isdownload} onFilter={applyfilter} onDownload={fetchallmembers}/> 
      <Results
	  years={allyears}
	  selectyear={selyear}
      isadmin={isadmin}
	  issearch={issearch}
	  onyearchange={filterbyyear}
    onpagechange={onpagechange}
    totalcount = {membercount}
        className={classes.results}
        orders={products} //
        onVerify={verifyfp}
      />
   {showverify ?  <Fingerverify isOpen={showverify} /> : null}
    </Page>
  );
});

export default Products;
