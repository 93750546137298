import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  CardMedia,
  colors,
  Table,
  TableHead,
  Button,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  cardroot: {
    maxWidth: 200,
    width: 200
  },
  content: {
    padding: theme.spacing(4)
  },
  marginTop: {
   // marginTop: theme.spacing(4)
  },
  dates: {
    padding: theme.spacing(0),
    backgroundColor: colors.grey[100]
  },
  imagecontent: {
    padding: theme.spacing(0)
  },
  media: {
    height: 100,
    paddingTop: '56.25%', // 16:9
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  }
}));

const Proofview = props => {
  const { order, customer, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Proofs" />
      <Divider />
      <CardContent className={classes.content}>
       
        <Grid
          className={clsx(classes.marginTop, classes.imagecontent)}
          container
          alignItems={"center"}
          direction="row"
          justify="space-around"
          spacing={2}
        >
         {props.documents.Member_certificates  && props.documents.Member_certificates.length >= 1 ?  <Grid item xs={3}>
              <Card className={classes.cardroot}>

                <CardMedia
                  className={classes.media}
                  image={props.documents.Member_certificates[0].proof_url}
                  title="Paella dish"
				  onClick={() => props.onPreview(props.documents.Member_certificates[0].proof_url)}

              />
              </Card>
          </Grid> : null}
          {props.documents.Member_certificates  && props.documents.Member_certificates.length >= 2 ?    <Grid item  xs={3}>
            <Card className={classes.cardroot}>

            <CardMedia
              className={classes.media}
              image={props.documents.Member_certificates[1].proof_url}
              title="Paella dish"
			  onClick={() => props.onPreview(props.documents.Member_certificates[1].proof_url)}

            />
            </Card>
            </Grid> : null}
            {props.documents.Member_certificates  && props.documents.Member_certificates.length >= 3 ? 
            <Grid item  xs={3}>
            <Card className={classes.cardroot}>

              <CardMedia
                className={classes.media}
                image={props.documents.Member_certificates[2].proof_url}
                title="Paella dish"
				onClick={() => props.onPreview(props.documents.Member_certificates[2].proof_url)}
              />
              </Card>
            </Grid> : null}
            </Grid>
        
      </CardContent>
    </Card>
  );
};

Proofview.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default Proofview;
