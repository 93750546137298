import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  },
  marginTop: {
    marginTop: 12
  },
  u: {  
    paddingBottom:"2px",  
    textDecorationStyle: "dotted",
    
},
  uo: {
    fontSize: 16,
    textDecoration: "underline",
  },
  middleDiv: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  templeName: {
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 8,
    marginTop: 8,
    textAlign: "center",
  },
  titlediv:{
    textAlign: "center",
  },
  userphoto:{
    textAlign: "right",
  },
  dates: {
    padding: theme.spacing(1),
    backgroundColor: colors.grey[100]
  },
  Title: {
    padding: "8px 8px",
    border: "1px solid #0000A0",
    borderRadius: 4,
    marginRight:"64px",
    marginLeft:"64px",
    textAlign: "center"
  },
  signature: {
    height: 20,
  },
}));

const Temple = props => {
  const { invoice, className, ...rest } = props;
  const [registerid, setRegisterid] = useState(0);

  const classes = useStyles();
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  useEffect(() => {
    if(invoice.Member_Registrations && invoice.Member_Registrations.length > 0)
    {
      let reg_id = invoice.Member_Registrations[0].register_id

      let register_year  = invoice.Member_Registrations[0].register_year
      let formatted_id = pad(reg_id,4)

      setRegisterid(formatted_id)
    }
     
  },[invoice])
  return (
    <Paper 
      {...rest}
      elevation={0}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <u />
        <Grid
          container
          justify="space-between"
         
        >
          <Grid item xs={2}>
            <img
              height={120}
              width={120}
              alt="Brand"
              src="/images/logos/print_logo.png"

            />
          </Grid>
          <Grid item xs={8}
      >
            <Grid container justify="center">
              <Grid item >  
              <div className={classes.middleDiv}>
        <div  className={classes.uo}>
          <Typography  component="body2" style={{color: "#0000A0"}}>உ</Typography>
        </div>
        <Typography align="center" component="body2"
          variant="body2" style={{fontFamily: "latha",color: "#0000A0"}}>
          ஸ்ரீ காளிகாம்பாள் துணை </Typography>
          <br></br>
        <div className={classes.templeName}>
          <Typography color="#0000A0" align="center" component="h3"
          variant="h3" style={{lineHeight: 1.2,color:"#0000A0", fontFamily: "latha"}}>
         <b> சென்னை ஸ்ரீ காளிகாம்பாள் <br></br>கமடேஸ்வரர் தேவஸ்தானம்</b>
          </Typography>
        </div>
        <Typography align="center" component="h6"
          variant="body2" style={{lineHeight: 1.2,color: "#0000A0", fontFamily: "latha"}}>
            212, தம்புச் செட்டி தெரு, சென்னை - 600 001. போன் : 25229624 <br></br>
          </Typography>
          <br></br>

        <div  className={classes.titlediv}>
        <Typography  style={{ fontFamily: "latha",color: "#0000A0"}} className={classes.Title}>
       <b>விஸ்வகர்ம சேவார்த்திகள் பதிவு ரசீது</b>
        </Typography>
        </div>
      
      </div>
      
              </Grid>
            </Grid>
          </Grid>
          <Grid item  xs={2}>
            <img
               height={120}
               width={120}
              alt="Brand"
              src={invoice.profile_pic ? invoice.profile_pic : "/images/avatar.jpg"}

            />
          </Grid>
        </Grid>
        <Grid
          alignItems="center"
          className={classes.marginTop}
          container
          justify="space-between"
        >
          <Grid item>
            <Typography variant="body2" style={{color: "#0000A0"}} variant="h6">எண்: &nbsp; {registerid}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" style={{color: "#0000A0"}} variant="h6" align="right">
              Date: {invoice.Member_Registrations ?  moment(invoice.Member_Registrations[0].createdAt).format(
                            'DD MM YYYY' 
                          ) : ""}
            </Typography>
          </Grid>
        </Grid>
      
        
      
     
            <div className={classes.marginTop}>

            <Typography align="center" variant="body2" style={{color:"#0000A0", lineHeight: 1.7,  fontFamily: "latha" }}>
            பிரம்மஸ்ரீ &emsp;<u className={classes.u}>{invoice.Name} </u>&emsp;அவர்களிடமிருந்து பைசா 0.12 மட்டும் {invoice.Member_Registrations ?  moment(invoice.Member_Registrations[0].createdAt).format(
                            'YYYY' 
                          ) : ""} ம் வருட சந்தாவாக பெற்றுக் கொள்ளப்பட்டது.

              </Typography>
              </div>

              <Grid  className={clsx(classes.marginTop)}
          container

          justify="space-between">
          <Grid item>
          <Typography
            align="left"
            component="h3"
            gutterBottom
            variant="overline"
            style={{color:  "#0000A0",marginRight: 16}}
          > 
         <b>   0.12 பைசா  </b>
          </Typography>
          </Grid>
          <Grid item>

         
          <div className={classes.signature}>
            </div>
               <Grid item xs={12} >
            
           
            <Typography
            align="right"
            component="h4"
            gutterBottom
            variant="overline"
            style={{color:  "#0000A0",marginRight: 16}}
          > 
         <b>   மேலாளர் </b>
          </Typography>
          </Grid>
          </Grid>
         
        </Grid>
     
   </CardContent>

   
    </Paper>
  );
};

Temple.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Temple;
