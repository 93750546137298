import React, { useRef ,useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import clsx from 'clsx';

import uuid from 'uuid/v1';
import moment from 'moment';
// import axios from 'utils/axios';
import  Page  from '../../components/Page';
import { Header,HeaderDiv, DocTitle, Body, Details,Temple,Proofview } from './components';
import { useReactToPrint } from 'react-to-print';
import { NetworkContext } from '../../context/NetworkContext';
import {

  Avatar,
  Card,
  CardMedia,
  Hidden,
  colors,
  Typography,
  Grid
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  cardroot: {
    maxWidth: 345,
    width: 345
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  dottedline: {
    borderTop: "1px dashed #0000A0",
   height: "1px"
  },
  pagesplit: {
    margin: theme.spacing(8, 0)
  }

  
}));
export const InvoiceDetails = withRouter(props => {

  const classes = useStyles();
  const [invoice, setInvoice] = useState({});
  const componentRef = useRef();
  const componentdocRef = useRef();

  const { sendNetworkRequest } = React.useContext(NetworkContext);
  const [community, setCommunity] = useState({});
  const [aadhaar, setAadhaar] = useState({});
  const [printyear, setPrintyear] = useState({});

  const [otherproof, setOtherproof] = useState({});

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintdoc = useReactToPrint({
    content: () => componentdocRef.current,
  });
  
  useEffect(() => {
    let mounted = true;

    const fetchInvoice = () => {
      // axios.get('/api/invoices/1').then(response => {
      //   if (mounted) {
      //     setInvoice(response.data.invoice);
      //   }
      // });
      setInvoice({
        id: uuid(),
        due_date: moment(),
        issue_date: moment().add(15, 'days'),
        ref: 'DEV-9483',
        customer: {
          name: 'Tracey Herman',
          company: 'Countdown Grey Lynn',
          nzbn: '6934656584231',
          address: '271 Richmond Rd, Grey Lynn, Auckland 1022, New Zealand'
        },
        products: [
          {
            id: uuid(),
            desc: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
            value: '55.50'
          }
        ],
        subtotal: '50.00',
        taxes: '5.50',
        total: '55.50',
        currency: '$',
        created_at: moment()
      })
    };

    fetchInvoice();

    return () => {
      mounted = false;
    };
  }, []);

  
  async function updateproductinfo(userid,year)
  {
    let prodinfo = await sendNetworkRequest('/getprintmemberinfo', {}, {reg_no : userid,year},false)
     if(prodinfo.members)
   {
     if(prodinfo.members.Member_certificates && prodinfo.members.Member_certificates.length > 1)
     {
        setCommunity(prodinfo.members.Member_certificates[0])

        prodinfo.members.Member_certificates.forEach(doc => {

            if(doc.proof_type == 1 && !community)
            {
              setCommunity(doc)
            }
            if(doc.proof_type == 2)
            {
              setAadhaar(doc)
            }
            if(doc.proof_type != 1 || doc.proof_type != 2)
            {
              setOtherproof(doc)
            }
        })


        
     }
   }
   setPrintyear(year)
    setInvoice(prodinfo.members);
  }
  
  useEffect(() => {
    const { id,year } = props.location.state
    
    updateproductinfo(id,year)
  }, []);

  if (!invoice) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Invoice Details"
    >

      <Header print={handlePrint} printdoc={handlePrintdoc} invoice={invoice} />

      <div id="printme"  ref={componentRef} >
      <Details invoice={invoice } printyear={printyear}  />
      <div  className={classes.dottedline} />
      <Temple invoice={invoice} />
      </div>
      <div id="printdoc"  ref={componentdocRef} >
        <Proofview invoice={invoice}/>
        </div>
     
    </Page>
  );
});

export default InvoiceDetails;
