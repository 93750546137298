import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';

import {
  Button,

  Checkbox,

  FormControlLabel,

  Grid
} from '@material-ui/core';

import 'react-quill/dist/quill.snow.css'; // ES6


import { NetworkContext } from '../../../../context/NetworkContext';
import { FilePond, registerPlugin } from "react-filepond"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import { Card ,
  CardContent,
  Typography,
} from '@material-ui/core';
 

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  papercontent: {
      marginTop : theme.spacing(2),
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  descriptionfield: {
    width: '100%'
   
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(2, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
   
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  nodataCont: {
    textAlign: "center",
    padding: "14px",
    fontSize: "18px",
    color: "#bfbfbf",
    fontWeight: 800
},
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    textAlign: 'right',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(5),
    '& > * + *': {
      margin: theme.spacing(0)
    }
  }
}));

const UserConformation = props => {
  const { open, onClose, onUpdate,usercontent,masters, viewtype, className, ...rest } = props;
  const [checked, setChecked] = React.useState(false);

  const classes = useStyles();

  const initialValues = {
    productName: '',
    productID:'',
    unit:'',
    brand:'',
    
    locations:'',
    alertqty : '',
    description : '',
  };
  const { sendNetworkRequest } = React.useContext(NetworkContext);



  const [values, setValues] = useState({
    ...initialValues
  });

 

  

  React.useEffect(() => {
   
 }, [values.category]);
  


  
  const handleoptionchange = type => (event, value) => {
    
    setValues({
      ...values,
      [type]: value
    });
   
   
  }
  
  async function capture()
  {
            var primaryUrl = "http://127.0.0.1:";
                let captureresponse = await axios({
                  method: 'POST', //you can set what request you want to be
                  url:  primaryUrl +"8004/mfs100/capture",
                  data: {
                    "Quality": 60,
                    "TimeOut": 10
                },
                  headers: {
                    'content-type':  "application/json; charset=utf-8"
                  }
                })
               
                setValues({
                  ...values,
                  'finger': captureresponse.data.BitmapData
                });
             //   props.onUpdate( captureresponse.data.BitmapData);
                props.onUpdate( captureresponse.data.IsoTemplate);

  }
  
  
  return (
    <>
    <Grid
      key={props.usercontent.id}
      container
      spacing={3}
    >
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardContent className={classes.content}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
      >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
        spacing={2}
      >
        {/* <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            multiline
            rowsMax={4}
            rows={4}
            value={values.signature}
            autoComplete="off"
            id="prefix"
            name="prefix"
            onChange={handlefieldChange('signature')}
            placeholder ="Fingerprint"
            label ="Fingerprint"
             /> */}
           {values.finger ? <img alt="other" src={`data:image/png;base64,${values.finger}`}/>
  : <img alt="other" src={`https://storage.googleapis.com/kaalikambal/fingerprint.png`}/>
           }  
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
        container
       
        spacing={2}
      >
         {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
            container
            direction="row"
            spacing={2}
          >
            
        <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={6}
        spacing={2}
      >
         <Button variant="contained" onClick={() => discoverdevice()} fullWidth color="primary" >
           Discover Device
              </Button>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={6}
        spacing={2}
      >

<Button variant="contained" onClick={() => deviceinfo()} fullWidth color="primary" >
           Device Info
              </Button>
      </Grid> 
    </Grid>*/}
  
    <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        container
        direction="row"
        spacing={2}
      >
        <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={6}
        spacing={2}
      >
                  <Typography variant="h4"  align="left" style={{color:"#0000A0", lineHeight: 1.7}}>

                 Fingerprint
</Typography>
         {/* <Button variant="contained" onClick={() => capture()} fullWidth color="primary" >
          Capture
              </Button> */}
              <FormControlLabel control={
              <Checkbox  onClick={(e) =>props.onUpdate(e.target.checked)}>Agree</Checkbox>}
              label="place right index finger to capture"></FormControlLabel>
      </Grid>
      {/* <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={6}
        spacing={2}
      >

<Button variant="contained"  fullWidth color="primary" >
         Reset
              </Button>
      </Grid> */}
    </Grid>
  
    </Grid>
                

     
      </Grid>
      <Grid
        
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        
          </Grid>
      </CardContent>
    </Card>
      
      </Grid>

    </Grid>
    {/* <Grid
        item
        className={classes.papercontent}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
    <CardContent className={classes.content}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
      >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <Autocomplete
                  id="combo-box-demo"
                  options={[
                    "Single",
                    "Combo",
                    "Variable"
                  ]}
                  margin="dense"
                  fullWidth
                  onChange={handleoptionchange('producttype')}
                  value={values.producttype}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label={"Product Type"} fullWidth margin="dense" variant="outlined" />}
                /> 
      </Grid> 
    
     
      </Grid>
      </CardContent>
   
    </Card>
      
      </Grid> */}

      {/* <Grid
        item
        className={classes.papercontent}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader  action={
          <Button size="small" color={'primary'}
            onClick={() => addvariant()}
          variant={'outlined'}>
            Add Variant

          </Button>
        }
        title="Add Variant"
        subheader="Hint for variant"></CardHeader>
        <CardContent className={classes.content}>
            <div className={classes.inner}>
              <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>S no</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Purchase Price(Exc Tax)</TableCell>
                    <TableCell>Purchase Price(Inc Tax)</TableCell>
                    <TableCell>Margin %</TableCell>
                    <TableCell>Selling Price(Exc Tax)</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.slice(0, rowsPerPage).map((order,index) => (
                    <TableRow
                      key={order.id}
                      selected={selectedOrders.indexOf(order.id) !== -1}
                    >
                 
                      <TableCell>
                        {index + 1}
                        
                      </TableCell>
                      <TableCell>{'BB0001-SAW1212'}</TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Purchase Price(Inc Tax)"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Purchase Price(Exc Tax)"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Margin %"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Selling Price(Exc Tax)"
                        />
                      </TableCell>
                      <TableCell>

                      <Avatar alt={order.name} src={`http://128.199.83.45/moogambika_dev${order.imageurl}`} />

                        </TableCell>
                    
                      
                     
                      
                     
                      <TableCell align="center">
                      
                        <GenericMoreButton /> 
                      </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {orders.length > 0 ? "" : <Typography className={classes.nodataCont} component={"h6"} >No Records found</Typography>}
               </TableContainer>   
            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
     
    </Card>
      
      </Grid> 
      {isadd &&  <AddVariant 
        onDismiss={dismiss}
      />   }                 
    */}
    </>
  );
};

UserConformation.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool.isRequired,
  masters: PropTypes.object
};

export default UserConformation;
