import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';

import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import PersonIcon from '@material-ui/icons/PersonOutline';

import { Label } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CustomerInfo = props => {
  const { customer, className, ...rest } = props;

  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };
  function calculateage(dob)
  {
    if(dob)
    {
      var yearselected = localStorage.getItem('Year');

      var today = new Date(yearselected);
    var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    console.log(age_now);
      return age_now;
    }else
    {
      return ""
    }
    
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={props.title} />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{customer.Name}</TableCell>
            </TableRow>
            <TableRow selected>
            <TableCell>Age, DOB</TableCell>
              <TableCell>
			  {calculateage(customer.dob)} ,{moment(customer.dob).format(
                            ' DD MMM YYYY' 
                          )}
			  </TableCell>
            </TableRow>
            <TableRow >
            <TableCell>Profile Pic</TableCell>
              <TableCell>            
				  <Avatar alt="Arun Jay" src={customer.profile_pic} variant="square" className={classes.large} />
</TableCell>
             
            </TableRow>
            <TableRow selected>
              <TableCell>Email</TableCell>
              <TableCell>{customer.email}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>Mobile</TableCell>
              <TableCell>{customer.mobile}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell>Address</TableCell>
              <TableCell>{customer.address}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>Aadhaar</TableCell>
              <TableCell>{customer.aadhaar}</TableCell>
            </TableRow>
            <TableRow selected>
              <TableCell >Area</TableCell>
              <TableCell>{customer.area}
			  
			  </TableCell>
            </TableRow>
            <TableRow >
              <TableCell>City</TableCell>
              <TableCell>{customer.city} - {customer.pincode}</TableCell>
            </TableRow>
           
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
       {props.isedit == true || props.isrenew == false ? <Button  component={RouterLink}
                                to={{ 
                                  pathname: "/edituser", 
                                  state: {
                                    id : customer.id,
                                    isedit : true
                                  }
                                 }}
                                 variant="contained" color="primary" size="small" onClick={handleEditOpen}>
          Edit
        </Button>
    : null }
      </CardActions>
     
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default CustomerInfo;
