import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import data from "./data.json"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker } from "@material-ui/pickers";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Button,
  Chip,
  TextField,
  CardActionArea
} from '@material-ui/core';
import { EventContext } from '../../context';
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  fixedTag: {
    padding: 0,
    '& .MuiOutlinedInput-root': {
      padding: 0,
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function AddEventinfo(props) {
  const classes = useStyles();

  const { eventCtx, setEventCtx } = React.useContext(EventContext);
  const { className, ...rest } = props;
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [selectedDate, handleDateChange] = useState(new Date());
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [eventinfo, setEventinfo] = useState({});

  const [endDate, setEndDate] = useState(moment());
  const handleChange = type => (event, value) => {
    setEventinfo({ ...eventinfo, [type]: value })

  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.onClose()
  };

  const handlesave = () => {
    props.onAdd(eventinfo)
  };
  const handleCalendarOpen = edge => {
    setSelectEdge(edge);
  };
  const handledateChange  = type => (date) => {
    setEventinfo({...eventinfo,[type]: date})
  }
  const handleInputChange = type => (event) => {
    setEventinfo({...eventinfo,[type]: event.target.value})
  }
  
  const handleCalendarChange = date => {
    setCalendarDate(date);
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        fullScreen={fullScreen}
        onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableBackdropClick>
        <DialogTitle id="customized-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container xs={12} sm={12} spacing={2}>
            <Grid item xs={6} >
              <Autocomplete
                id="eventtype"
                className={classes.fixedTag}
                defaultValue={eventCtx.themes}
                onChange={handleChange('session')}
                getOptionLabel={(option) => option.name}
                options={props.eventsessions}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={data.eventtype}
                    margin="dense"
                    variant="outlined"
                    fullWidth

                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} >

              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                // value={name}
                id="discountvalue"
                name="discountvalue"
                onChange={handleInputChange('eventname')}
                // onChange={handlechange('name')}
                label={data.eventname}
              />
            </Grid>
            <Grid item xs={6} sm={6} >

              <DateTimePicker
                label={data.eventstartdate}
                fullWidth
                inputVariant="outlined"
                value={eventinfo.startDate}
                margin="dense"
                minDate={new Date()}
                onChange={handledateChange('startDate')}
              />
            </Grid>
            <Grid item xs={6} sm={6} >
              <DateTimePicker
                label={data.eventenddate}
                fullWidth
                inputVariant="outlined"
                minDate={eventinfo.startDate}
                margin="dense"
                value={eventinfo.endDate}
                onChange={handledateChange('endDate')}
                strictCompareDates={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="eventtype"
                multiple
                className={classes.fixedTag}
                defaultValue={eventCtx.themes}
                getOptionLabel={(option) => option.name}
                onChange={handleChange('participants')}
                options={eventCtx.masterData.masterparticipants}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" size="small" label={option.name} {...getTagProps({ index })} />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={data.participants}
                    margin="dense"
                    variant="outlined"
                    fullWidth

                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>

              <FormControlLabel
                control={
                  <Checkbox
                    // checked={state.checkedB}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }

                label={data.addresssame}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={4}
                onChange={handleInputChange('address')}
                id={data.address}
                name={data.address}
                label={'Address'}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12} >

                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  onChange={handleInputChange('contactname')}
                  label={data.contactname}
                />
              </Grid>
              <Grid item xs={12} >

                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  onChange={handleInputChange('contactnumber')}
                  label={data.contactphone}
                />
              </Grid>
            </Grid>
            

            <Grid item xs={12}>


              <TextField
                variant="outlined"
                margin="dense"
                multiline
                rows={4}
                fullWidth
                onChange={handleInputChange('description')}

                label={data.description}
              />

            </Grid>







          </Grid>

          <Grid item xs={6} >
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => props.onClose()} variant="contained" >
            Cancel
        </Button>
          <Button size="small" variant="contained" color="primary" onClick={() => handlesave()}>
            ADD
        </Button>
        </DialogActions>
      </Dialog>



    </React.Fragment>
  );
}