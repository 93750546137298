import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    fontSize: 16,
  },
  no_date: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  body: {
    lineHeight: 2.16,
    fontSize: 18,
    marginTop: "8px",
    textAlign: "justify",
  },
  subTitle: {
    marginTop: "1%",
  },
  textTop: {
    fontWeight: 600,
  },
  pledgediv: {
    marginTop: "1%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1%",
  },
  details: {
    marginTop: "16px%",
    display: "flex",
    justifyContent: "space-between",
  },
  signature: {
    width: "auto",
    height: 100,
  },
  signatureText: {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: "8px",
    textAlign: "center",
  },
  nameDetails: {
    display: "flex",
    marginTop: "8px",
  },
  nametitle: {
    fontWeight: 500,
  },
  Title: {
    padding: "14px 6px",
    border: "1px solid #000",
    borderRadius: 4,
  },
}));

export function Body(props) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div className={classes.no_date}>
        <Typography className={classes.textTop}>எண்:&nbsp;<b>8000</b></Typography>
        <Typography className={classes.textTop}>
        Date : 12  Mar 2021 06:52 AM
          </Typography>
      </div>
      <div className={classes.subTitle}>
        <Typography style={{ fontWeight: 600 }}>
        மதிப்பிற்குரிய அறங்காவலர் அவர்களுக்கு,
        </Typography>
      </div>
      <div className={classes.body}>
        <Typography style={{ lineHeight: 2.2, fontSize: 17 }}>
        மேற்படி தேவஸ்தானத்திற்காக ஏற்படுத்தப்பட்ட உயர்நீதிமன்ற சி.எஸ்.எண். 62/33,  நிர்வாகத் திட்டப்படி 2020 வருடத்திய சேவார்த்திகள் பதிவேட்டில் ( Register of worshippers ) என் பெயரைப் பதிவு செய்யுமாறு தங்களைக் கேட்டுக் கொள்கிறேன். இத்துடன் ஒரு வருஷ சந்தாவாக 0.12 பைசா மட்டும் செலுத்தி இருக்கிறேன். <br></br>

        </Typography>

        <div className={classes.details}>
          <div className={classes.name_other_details}>
            <div className={classes.nameDetails}>
              <div className={classes.nametitle}>பெயர் &nbsp;:&nbsp;</div>
              <div className={classes.name}><u>G Vinoth</u></div>
            </div>
            <div className={classes.nameDetails}>
              <div className={classes.nametitle}>
              தகப்பனார் பெயர் &nbsp;:&nbsp;
              </div>
              <div className={classes.name}><u>S Govintharaj</u></div>
            </div>
            <div className={classes.nameDetails}>
              <div className={classes.nametitle}>வயது &nbsp;:&nbsp;</div>
              <div className={classes.name}><u>32</u></div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div className={classes.nametitle}>தொழில் &nbsp;:&nbsp;</div>
              <div className={classes.name}><u>Clerk</u></div>
              <div className={classes.nametitle}> விலாசம்  &nbsp;:&nbsp;</div>
              <div className={classes.name}><u>22/8, naineappa theru,pattalam, chennai: 600012.</u></div>
            </div>
          </div>
          <div className={classes.signaturediv}>
            <Typography className={classes.signatureText}>Signature</Typography>
            {/* <img
              className={classes.signature}
              src="https://www.cognidox.com/hs-fs/hubfs/Digital%20Signature%20MHRA%20Remote%20Working.jpg?width=750&name=Digital%20Signature%20MHRA%20Remote%20Working.jpg"
              alt="signature"
            /> */}
          </div>
        </div>
      </div>

      <div className={classes.pledgediv}>
      <div className={classes.uo}>
        <Typography className={classes.Title}>
        உறுதிமொழி
        </Typography>
      </div>
    </div>
    <div className={classes.body}>
        <Typography style={{ lineHeight: 2.2, fontSize: 17 }}>
        <u>G.வினோத் </u>ஆகிய நான் விஸ்வகர்ம சமூகத்தைச் சேர்ந்தவன் என்று உறுதி கூறுகிறேன்.
          </Typography>
          <Typography className={classes.signatureText}>Signature</Typography>


         
     </div>     
    
    </div>
  );
}
