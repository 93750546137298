import React from 'react';

const productuploadCtxInitial = {
    productCtx: {  
        product_categoy: "",
        steps: ['Step1', 'Step2','Step3','Step4'],
        error_message:{ },
        componentnames:[],
        primarycomponent:{},
        membername: "",
        //department: "",
        speciality: ""
    },
    issingle: "",
    components:[],
    componentnames:[],
    primarycomponent: {},
    masterData: {},
    setProductCtx: () => null
}

export const ProductContext = React.createContext(productuploadCtxInitial);

export const ProductConsumer = ProductContext.Consumer;

const mapDataToCtx = (apiData, mapper, mappertype) => {
    if(Object.keys(apiData).length === 0) return {};
    productuploadCtxInitial.productCtx[mappertype] = mapper(apiData);

    return productuploadCtxInitial.productCtx;
}

export const ProductProvider = props => {

    const [ productCtx, setProductCtx  ] = React.useState(mapDataToCtx(props.value.data, props.value.mapper,props.value.mappertype));
    // on Mount get Data from storage
    // on Unmount store data to storage

    React.useEffect(() => {
        console.info('productCtx s',productCtx);
    },[productCtx.masterData])

    return(
        <ProductContext.Provider value={{ productCtx, setProductCtx, masterData: mapDataToCtx(props.value.data, props.value.mapper, props.value.mappertype) }} >
            {props.children}
        </ProductContext.Provider>      
    )
}