import gql from 'graphql-tag';
const MASTERSPECIALITES =`
query MyQuery($departmentId: Int!) {
  allSpecialities(orderBy: NAME_ASC, condition: {departmentId: $departmentId}) {
    nodes {
      shortCode
      departmentId
      id
      name
      isActive
    }
  }
}`;
const PRODUCTCATEGORY = gql`query {
    allMasterProductCategories{
        nodes {
            alias
            name
            id
          }
    }
    allMasterProductTypes {
    nodes {
      alias
      id
      name
      shortCode
    }
  }
  allMasterMaterials {
    nodes {
      alias
      id
      name
    }
  },
  allMasterGenders {
    nodes {
      alias
      id
      name
    }
  },
  allMasterDiamondTypes {
    nodes {
      diamondClarity
      diamondColor
      id
    }
  }
  allMasterMetalsColors {
    nodes {
      alias
      id
      name
      shortCode
    }
  },
  allMasterDiamondsSettings {
    nodes {
      alias
      id
      name
    }
  },
  allMasterDiamondsShapes {
    nodes {
      name
      id
      alias
    }
  },
  allMasterThemes {
    nodes {
      alias
      id
      name
    }
  },

  allMasterStyles {
    nodes {
      alias
      id
      name
    }
  },
  allMasterOccasions {
    nodes {
      alias
      id
      name
    }
  },
  allMasterCollections {
    nodes {
      id
      alias
      name
    }
  },
  allMasterStonesColors {
    nodes {
      alias
      id
      name
    }
  },
  allMasterStones {
    nodes {
      alias
      id
      name
    }
  },
  allMasterMetalsColors {
    nodes {
      alias
      id
      name
      shortCode
    }
  },
  allMasterMetalsPurities {
    nodes {
      alias
      id
      name
      shortCode
    }
  },
  allMasterDiamondsColors {
    nodes {
      alias
      id
      name
      shortCode
    }
  },
  allMasterDiamondClarities {
    nodes {
      alias
      id
      name
    }
  },
  allMasterVendors {
    nodes {
      id
      name
      shortCode
      vendorDelivaryDays
    
    }
  }
  allProductLists(orderBy: PRODUCT_SERIES_DESC, first: 1) {
    nodes {
      productSeries
      id
    }
  },
  allMasterGemstonesTypes {
    nodes {
      alias
      colorCode
      id
      name
      shortCode
    }
  },
  allMasterGemstonesShapes {
    nodes {
      alias
      id
      name
    }
  },
  allMasterEarringBackings {
    nodes {
      name
      createdAt
      alias
      id
      nodeId
      updatedAt
    }
  }
  allMasterGemstonesSettings {
    nodes {
      alias
      id
      name
    }
  }
  }`
const PRODUCTLIST = gql`
query MyQuery($Veiw: Int!, $Offset: Int!) {
  allProductLists(first: $Veiw, offset: $Offset) {
    nodes {
      id
      nodeId
      productName,
      productCategory,
      productType
      productId,
      createdAt
      isactive,
      transSkuListsByProductId(condition: {isdefault: true}) {
        nodes {
          skuUrl
          discount
        }
      }
    }
    totalCount
  }
}
`;
const PRODUCTLISTSTATUSEDIT = gql`
mutation MyMutation($productId:String!,$isActive:Boolean!) {
  __typename
  updateProductListByProductId(input: {productId: $productId, productListPatch: {isactive: $isActive}}) {
    clientMutationId
    productList {
      isactive
    }
  }
}
`;
const PRODUCTDIAMONDTYPES = `
query MyQuery {
  allMasterDiamondTypes {
    nodes {
      diamondClarity
      diamondColor
      id
    }
  }
}
`;
const PRODUCTEDIT = `
query MyQuery($productId: String!) {
  productListByProductId(productId: $productId) {
    productMetalcoloursByProductId {
      nodes {
        productColor
        id
      }
    }
    productName
    productType
    vendorCode
    gender
    productDiamondsByProductSku {
      nodes {
        diamondClarity
        diamondColour
        diamondSettings
        diamondShape
        diamondType
        id
        stoneCount
        stoneWeight
      }
    }
    productGemstonesByProductSku {
      nodes {
        gemstoneSetting
        gemstoneShape
        gemstoneSize
        gemstoneType
        gemstonsSize
        id
        stoneCount
        stoneWeight
      }
    }
    productImagesByProductId {
      nodes {
        id
        imagePosition
        imageUrl
        productColor
      }
    }
    productPuritiesByProductId {
      nodes {
        purity
        id
      }
    }
    productThemesByProductId(condition: {isActive: true}) {
      nodes {
        themeName
        id
      }
    }
    productStonecolorsByProductId {
      nodes {
        id
        stonecolor
      }
    }
    productStylesByProductId(condition: {isActive: true}) {
      nodes {
        styleName
        id
      }
    }
    productCollectionsByProductId {
      nodes {
        collectionName
        id
      }
    }
    productOccassionsByProductId(condition: {isActive: true}) {
      nodes {
        occassionName
        id
      }
    }
    productStonecountsByProductId {
      nodes {
        id
        stonecount
      }
    }
    transSkuListsByProductId {
      nodes {
        skuSize
        diamondType
        metalColor
        purity
        productId
        skuWeight
        generatedSku,
        costPrice
        costPriceTax
        discountPrice
        discountPriceTax
        markupPrice
        markupPriceTax
        sellingPrice
        sellingPriceTax
        isReadyToShip
        isActive
        isdefault,
        vendorDeliveryTime
        id
        isActive
        transSkuDescriptionsBySkuId {
          nodes {
            skuDescription
            certificate
            ringsizeImage
          }
        }
      }
    }
    productCategory
    sizeVarient
  }
}

`;

const MASTERDEPARTMENTS =`
query MyQuery {
  allDepartments {
    nodes {
      id
      name
      isActive
      createdBy
      createdAt
      shortCode
      updatedAt
    }
  }
}`;
const MASTERSPECIALITY =`
query MyQuery {
  allSpecialities {
    nodes {
      createdAt
      createdBy
      departmentId
      id
      name
      isActive
      nodeId
      shortCode
      updatedAt
    }
  }
}`;

const MASTERPARTICIPANTS =`
query MyQuery {
allMasterparticipants {
  nodes {
    createdBy
    id
    createdAt
    isActive
    name
    shortCode
    updatedAt
  }
}
}`;
const MASTEREVENTS =`
query MyQuery {
  allMasterevents {
    nodes {
      name
      shortCode
      updatedAt
      isActive
      createdBy
      createdAt
      id
    }
  }
}`;
  export {
    PRODUCTCATEGORY,
    PRODUCTLIST,
    PRODUCTEDIT,
    PRODUCTLISTSTATUSEDIT,
    PRODUCTDIAMONDTYPES,
    MASTERSPECIALITES,
    MASTERDEPARTMENTS,
    MASTERSPECIALITY,
    MASTEREVENTS,
    MASTERPARTICIPANTS
  }