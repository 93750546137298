import React, { useState,useRef,useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from "axios";
import { ReactTransliterate } from "react-transliterate";
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from "@material-ui/pickers";

import {
 
  TextField,

  Grid
} from '@material-ui/core';

import "react-transliterate/dist/index.css";

import 'react-quill/dist/quill.snow.css'; // ES6
import moment from 'moment';

import { NetworkContext } from '../../../../context/NetworkContext';

import { Card ,
  CardContent} from '@material-ui/core';
  import "./style.css"


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  papercontent: {
      marginTop : theme.spacing(2),
  },
  fieldinput:{
    width: "100%", height: "36px", marginTop : "24px",
    border: "1px solid #cccccc",
    borderRadius: 4,
    
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  descriptionfield: {
    width: '100%'
   
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(2, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
   
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  nodataCont: {
    textAlign: "center",
    padding: "14px",
    fontSize: "18px",
    color: "#bfbfbf",
    fontWeight: 800
},
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    textAlign: 'right',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(5),
    '& > * + *': {
      margin: theme.spacing(0)
    }
  },
  formContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 30
},
  card: {
    maxHeight: "100px",
    maxWidth:"100px",
    width: "100px",
    height: "100px",
    textAlign: "left",
    boxShadow: "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)"
},
}));

const ProductDetails = props => {
  const { open, onClose, onUpdate,usercontent,masters, viewtype, className, ...rest } = props;
  const inputEl = useRef(null);

  const classes = useStyles();

  const initialValues = usercontent ? usercontent :  {
    productName: '',
    productID:'',
    unit:'',
    brand:'',
    city:"சென்னை",
    locations:'',
    alertqty : '',
    description : '',
  };
  const { sendNetworkRequest } = React.useContext(NetworkContext);

  const [expandProject, setExpandProject] = useState(true);
  const [expandCustomer, setExpandCustomer] = useState(false);
  const [subcommodity, setSubcommodity] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isadd, setIsadd] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [url, setUrl] = useState(props.usercontent ? props.usercontent.avatar : "");

  const [orders, setOrders] = useState([{
    },
    {

    },
    {

    },
    {

    }]);

  const [values, setValues] = useState(props.usercontent ? {
    ...props.usercontent} : {
    ...initialValues
  });

  const handleClear = () => {
    setValues({ ...initialValues });
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  function dismiss()
  {
    setIsadd(false)
  }
  function addproduct()
  {
    props.onUpdate(values)
  }
  async function updateuser(userobj)
  {
    setIsadd(true)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };
  const [imgCollection, setImgCollection] = useState(props.avatarobj ? props.avatarobj : '')

  const onFileChange = (files) => {
      let items = files.map(fileItem => fileItem.file)
   //  setImgCollection([...imgCollection, items])
    //  if(items.length > 0 )
    //  {
    //   uploadimage(items[0].file)

    //  }
  }




 React.useEffect(() => {
  setValues({
    ...props.usercontent
  })
  setUrl(
    props.usercontent  ? props.usercontent.avatar : ""
  )
}, [props.usercontent]);

const handleAssetChange =  (e) => {
  uploadimage(e.target.files[0])
}


  
  async function uploadimage(imgdata) {
   // onUpdate && onUpdate(values);

   var formData = new FormData()
   formData.append('photo', imgdata)
   formData.append('reg_id', "0001")

   setImgCollection([...imgCollection, imgdata])
  //  for (let img in imgCollection[0]) {
  //      formData.append('photo', imgCollection[0][img])
  //  }

   axios.post('https://api.chennaisrikalikambal.in/uploads', formData, {
 
}).then(res => {

      //  setValues(values => ({
      //   ...values,
      //   ['profile_pic']: res.data.url
      // }));
     props.onUpdate('avatar',res.data.url);
    // props.updateavatarobj(imgdata);
    setUrl(res.data.url)
   //  props.onUpdate('avatar',imgdata)

   }).catch(err => {
    // what now?
    alert(JSON.stringify(err));
})
  };

  const handleinputChange = (value) => {
    setValues(values => ({
      ...values,
      ['description']: value
    }));
  }
  const handlefieldChange = type => e => {
    let value =  e.target.value;
    if(type === "mobile" && value.length > 10)
    {
      return
    }
    if(type === "aadhaar_no" && value.length > 12)
    {
      return
    }
    if(type === "pincode" && value.length > 6)
    {
      return
    }
    setValues({
      ...values,
      [type]: e.target.value
    });
    props.onUpdate(type, e.target.value);
   // alert(JSON.stringify(productCtx))
  }
  const handledobChange = type => e => {
    var a = moment();
var b = moment(e, 'MM-YYYY');
var age = moment.duration(a.diff(b));
var years = age.years();
var months = age.months();
let age1 = years;
props.onUpdate(age, age1);
props.onUpdate(type, e);

setValues({
      ...values,
      [type]: e,
      age: age1
    });
  }
  
  async function getsubcategory()
  {
    let subcategory = await sendNetworkRequest('/api/getsubtcategory', {}, {},false)
    setValues({...values, mastersubcategory : subcategory});
  }
  const handleoptionchange = type => (event, value) => {
    
    setValues({
      ...values,
      [type]: value
    });
   
   
  }

 
 
  return (
    <>
    <Grid
      key={props.usercontent.id}
      container
      spacing={3}
    >
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardContent className={classes.content}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
      >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
        spacing={2}
      >
            <div className="container">

              <ReactTransliterate
                 className={classes.fieldinput}
                value={values.name}
                onChange={handlefieldChange('name')}
                lang="ta"
                autoComplete="off"
                placeholder="Name"
                containerStyles={{
                  width: "100%",
                  height: "80px"
                }}
              />
       </div>
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
        spacing={2}
      >
           <div className="container">

<ReactTransliterate
className={classes.fieldinput}
 value={values.fatherName}
 onChange={handlefieldChange('fatherName')}
 lang="ta"
 placeholder="Father Name"
 containerStyles={{
   width: "100%",
   height: "80px"
 }}
/>
</div>
       
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.email ? values.email : ""}

            autoComplete="off"
            id="firstname"
            name="firstname"
            onChange={handlefieldChange('email')}

            placeholder="Email"
            label="Email"
             />
      </Grid>
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
         <TextField
            variant="outlined"
            margin="dense"
            type="number"
            fullWidth
            value={values.mobile ? values.mobile : ""}
            autoComplete="off"
            id="firstname"
            name="firstname"
            
            onChange={handlefieldChange('mobile')}
            inputProps={{
              maxlength: 10
            }}
            placeholder="Mobile"
            label="Mobile"
             />
      </Grid> 
      {/* <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <Autocomplete
                  id="combo-box-demo"
                  options={['Male','FeMale','Others']}
                  margin="dense"
                  fullWidth
                  onChange={handleoptionchange('gender')}
                  value={values.gender}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label={"Gender"} fullWidth margin="dense" variant="outlined" />}
                /> 
      </Grid>  */}

<Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        container
        direction="row"
      >
        <Grid
        item
        lg={8}
        md={12}
        xl={8}
        xs={12}
        spacing={2}
      >
        <DatePicker
        disableFuture
        openTo="year"
        format="DD MMM yyyy"
        label="Date of birth"
        views={["year", "month", "date"]}
        inputVariant="outlined"
        margin="dense"
        fullWidth
        value={values.dob}
        onChange={handledobChange('dob')}

      //  onChange={handleDateChange}
      />
             </Grid>
              <Grid
        item
        lg={4}
        md={12}
        xl={4}
        xs={12}
        spacing={2}
      >
               <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.age ? values.age : null}
            autoComplete="off"
            id="age"
            name="age"
            onChange={handlefieldChange('age')}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            placeholder="Age"
            label="Age"
             />
             </Grid>
      </Grid> 
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        
        spacing={2}
      >
        {/* <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.Occupation}
            autoComplete="off"
            id="firstname"
            name="firstname"
            onChange={handlefieldChange('Occupation')}

            placeholder="Occupation"
            label="Occupation"
             /> */}
             <ReactTransliterate
                        className={classes.fieldinput}
                        value={values.occupation}
                        onChange={handlefieldChange('occupation')}
                        lang="ta"
                        placeholder="Occupation"
                        containerStyles={{
                          width: "100%",
                          height: "80px"
                        }}
                        />
      </Grid> 
     <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
  <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            type="number"
            value={values.aadhaar_no ? values.aadhaar_no : "" }
            autoComplete="off"
            id="prefix"
            name="prefix"
            onChange={handlefieldChange('aadhaar_no')}
            placeholder ="Aadhaar"
            label ="Aadhaar"
             />

{/* <ReactTransliterate
                        className={classes.fieldinput}
                        value={values.ward}
                        onChange={handlefieldChange('ward')}
                        lang="ta"
                        placeholder="ward"
                        containerStyles={{
                          width: "100%",
                          height: "80px"
                        }}
                        /> */}
      </Grid>  
     
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        {/* <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.address}
            autoComplete="off"
            multiline
            rowsMax={3}
            id="prefix"
            name="prefix"
            onChange={handlefieldChange('address')}
            placeholder ="Address"
            label ="Address"
             /> */}

<ReactTransliterate
                        className={classes.fieldinput}
                        value={values.address}
                        onChange={handlefieldChange('address')}
                        lang="ta"
                        placeholder="Address"
                        containerStyles={{
                          width: "100%",
                          height: "80px"
                        }}
                        />
      </Grid> 
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        {/* <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.city}
            autoComplete="off"
            multiline
            rowsMax={3}
            id="prefix"
            name="prefix"
            onChange={handlefieldChange('city')}
            placeholder ="Ward Number"
            label ="Ward Number"
             /> */}
             <ReactTransliterate
                        className={classes.fieldinput}
                        value={values.area}
                        onChange={handlefieldChange('area')}
                        lang="ta"
                        placeholder="Area"
                        containerStyles={{
                          width: "100%",
                          height: "80px"
                        }}
                        />
      </Grid> 
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        {/* <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.state}
            autoComplete="off"
            multiline
            rowsMax={3}
            id="prefix"
            name="prefix"
            onChange={handlefieldChange('state')}
            placeholder ="Area"
            label ="Area"
             /> */}
             <ReactTransliterate
                        className={classes.fieldinput}
                        value={"சென்னை"}
                        disabled
                        // onChange={handlefieldChange('city')}
                        lang="ta"
                        placeholder="City"
                        containerStyles={{
                          width: "100%",
                          height: "80px"
                        }}
                        />
      </Grid> 
  
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        {/* <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            value={values.state}
            autoComplete="off"
            multiline
            rowsMax={3}
            id="prefix"
            name="prefix"
            onChange={handlefieldChange('state')}
            placeholder ="City"
            label ="City"
             /> */}

                  <ReactTransliterate
                        className={classes.fieldinput}
                        type="number"
                        value={values.pincode}
                        onChange={handlefieldChange('pincode')}
                        lang="ta"
                        placeholder="pincode"
                        containerStyles={{
                          width: "100%",
                          height: "80px"
                        }}
                        />
      </Grid> 
     
                                                
      <Grid  item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2} 
           style={{ width: "150px", wordBreak: "break-all", height: "150px", padding: '8px', margin: '0', cursor: "pointer", position: "relative" }} className="container" >
                       <Card className={classes.card}>
                       <div style={{ position: "relative" }}>
                                 
                                 <i className="fa fa-window-edit" style={{ position: "absolute", right: 10, top: 11, color: "red", zIndex: '12' }}  />
                                                          <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    multiple
                                                                    onChange={e => handleAssetChange(e
                                                                    )}

                                                                ></input>
                                                      
                                                            <img src={url ? url : "/images/addphoto.jpg"}
                                                                style={{ width: "100%", height: "100%", borderRadius: "4px" }} />
                                                     </div> 
                                                     </Card> 
                                                </Grid>  
                                               

      {/* <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <div className="filepond-wrapper">
        <FilePond 
           
                        files={[
                          {
                            source: "https://picsum.photos/200/300",
                            options: { type: "local" }
                          }
                        ]}
                        server={{
                          load: (source, load, error, progress, abort, headers) => {
                            var myRequest = new Request(source);
                            fetch(myRequest).then(function(response) {
                              response.blob().then(function(myBlob) {
                                load(myBlob);
                              });
                            });
                          }
                        }}
            allowMultiple={false}
            allowImageCrop={true}
            labelIdle={"Upload User photo"}
            onupdatefiles={(fileItems) =>{
              if(fileItems && fileItems.length > 0)
              {
                uploadimage(fileItems[0].file)

              }
            }}
            onprocessfile={(error, file) => { 
            
            }}         
            >
        </FilePond>
    </div>
        </Grid>             */}

     
      </Grid>
      <Grid
        
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        
          </Grid>
      </CardContent>
    </Card>
      
      </Grid>

    </Grid>
    {/* <Grid
        item
        className={classes.papercontent}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
    <CardContent className={classes.content}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
      >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <Autocomplete
                  id="combo-box-demo"
                  options={[
                    "Single",
                    "Combo",
                    "Variable"
                  ]}
                  margin="dense"
                  fullWidth
                  onChange={handleoptionchange('producttype')}
                  value={values.producttype}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label={"Product Type"} fullWidth margin="dense" variant="outlined" />}
                /> 
      </Grid> 
    
     
      </Grid>
      </CardContent>
   
    </Card>
      
      </Grid> */}

      {/* <Grid
        item
        className={classes.papercontent}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader  action={
          <Button size="small" color={'primary'}
            onClick={() => addvariant()}
          variant={'outlined'}>
            Add Variant

          </Button>
        }
        title="Add Variant"
        subheader="Hint for variant"></CardHeader>
        <CardContent className={classes.content}>
            <div className={classes.inner}>
              <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>S no</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Purchase Price(Exc Tax)</TableCell>
                    <TableCell>Purchase Price(Inc Tax)</TableCell>
                    <TableCell>Margin %</TableCell>
                    <TableCell>Selling Price(Exc Tax)</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.slice(0, rowsPerPage).map((order,index) => (
                    <TableRow
                      key={order.id}
                      selected={selectedOrders.indexOf(order.id) !== -1}
                    >
                 
                      <TableCell>
                        {index + 1}
                        
                      </TableCell>
                      <TableCell>{'BB0001-SAW1212'}</TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Purchase Price(Inc Tax)"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Purchase Price(Exc Tax)"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Margin %"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Selling Price(Exc Tax)"
                        />
                      </TableCell>
                      <TableCell>

                      <Avatar alt={order.name} src={`http://128.199.83.45/moogambika_dev${order.imageurl}`} />

                        </TableCell>
                    
                      
                     
                      
                     
                      <TableCell align="center">
                      
                        <GenericMoreButton /> 
                      </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {orders.length > 0 ? "" : <Typography className={classes.nodataCont} component={"h6"} >No Records found</Typography>}
               </TableContainer>   
            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
     
    </Card>
      
      </Grid> 
      {isadd &&  <AddVariant 
        onDismiss={dismiss}
      />   }                 
    */}
    </>
  );
};

ProductDetails.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool.isRequired,
  masters: PropTypes.object
};

export default ProductDetails;
