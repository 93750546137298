import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link as RouterLink } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import { green } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/Check';

import CloudDownload from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import exportFromJSON from 'export-from-json'
import moment from 'moment';

import { Search, Filter } from './components';
import Download from 'react-pivot/lib/download';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480
  },
  addbtn: {
    flexGrow: 1,
    width: 240,
    flexBasis: 480
  },
  filterButton: {
    marginLeft: 'auto',
    width: 240,
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

const ProductFilter = props => {
  const { onFilter,userslist, onSearch,masters,isadduser,onDownload,isDownloadready, className, ...rest } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
  const handleExportdata = async () => {
    setLoading(true);
let print_data = []

    userslist.forEach( prodinfo => {
      

    let community_obj = {};
    let aadhaaar_obj ={};
    let other_obj  = {}
   let memberinfo = prodinfo
   if(prodinfo.Member_certificates && prodinfo.Member_certificates.length > 1)
   {

      prodinfo.Member_certificates.forEach(doc => {

          if(doc.proof_type == 1)
          {
            community_obj = doc
          }
          if(doc.proof_type == 2)
          {
            aadhaaar_obj = doc
          }
          if(doc.proof_type != 1 || doc.proof_type != 2)
          {
            other_obj = doc
          }
      })


      
   }
 let age = ""
   if(memberinfo.dob)
   {
     let dob = memberinfo.dob
     var today = new Date();
   var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
   var age_now = today.getFullYear() - birthDate.getFullYear();
   var m = today.getMonth() - birthDate.getMonth();
   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
   {
       age_now--;
   }
   console.log(age_now);
   age = age_now;
   }
   let userinfo ={
      member_id : memberinfo.id,
      registration_id : pad(memberinfo.Member_Registrations ?  memberinfo.Member_Registrations[0].register_id : 0,4),
      Name : memberinfo.Name,
      name : memberinfo.Name,
      fatherName : memberinfo.fatherName,
      avatar : memberinfo.profile_pic,
      email : memberinfo.email,
      mobile : memberinfo.mobile,
      dob: memberinfo.dob,
      occupation: memberinfo.occupation,
      address : memberinfo.address,
      area : memberinfo.area,
      city : memberinfo.city,
      state : memberinfo.state,
      age,
      aadhaar_no: memberinfo.aadhaar,
      pincode : memberinfo.pincode,
      community : community_obj.proof_url,
    aadhaar : aadhaaar_obj.proof_url,
    otherproof:other_obj.proof_url
   
    }
    print_data.push(userinfo)

})

const fileName = 'download'+moment(new Date()).format(
  'DD _MMM_YYYY' 
)

const exportType = 'csv'

const data =JSON.stringify(print_data)
 await exportFromJSON({ data, fileName, exportType })
setSuccess(true);

setLoading(false);
window.setTimeout(() => {
  setSuccess(false);
}, 2000);
};
 
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item>
        <Search
          className={classes.search}
          onSearch={onSearch}
        />
      </Grid>
      <Grid item>
     {isadduser ? <Button
           className={classes.addbtn}
            color="primary"
            variant="contained"
            component={RouterLink}
            size="large"
            to={'/edituser'}
          >
            Add User
          </Button> : null }
      </Grid>
      <Grid item>
    <Button
          className={classes.filterButton}
           onClick={handleFilterOpen}
          size="large"
          variant="contained"
        >
         <FilterListIcon className={classes.filterIcon} />
           Filter
        </Button>

        </Grid>
      <Grid item>
    
                 
        <div className={classes.wrapper}>
        <Fab
          aria-label="download"
          color="primary"
          className={buttonClassname}
          onClick={isDownloadready ? handleExportdata : onDownload}
        >
          {isDownloadready ? <SaveIcon /> : <CloudDownload />}
        </Fab>
        {loading && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
      </Grid>
      
      <Filter
        onClose={handleFilterClose}
        onFilter={onFilter}
        open={openFilter}
        masters={masters}
      />
    </Grid>
  );
};

ProductFilter.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
  masters: PropTypes.object
};

export default ProductFilter;
