import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  colors,
  Hidden
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import EditIcon from '@material-ui/icons/Edit';

import getInitials from '../../utils/getInitials';
import { Label } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingBottom: 0
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  description: {
    padding: theme.spacing(2, 3, 1, 3)
  },
  tags: {
    padding: theme.spacing(0, 3, 1, 3),
    '& > * + *': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  },
  learnMoreButton: {
    marginLeft: theme.spacing(2)
  },
  likedButton: {
    color: colors.red[600]
  },
  shareButton: {
    marginLeft: theme.spacing(1)
  },
  details: {
    padding: theme.spacing(1, 3)
  }
}));

const ProjectCard = props => {
  const { project, className, ...rest } = props;

  const classes = useStyles();

  const [liked, setLiked] = useState(project.liked);

  const handleLike = () => {
    setLiked(true);
  };

  const handleUnlike = () => {
    setLiked(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        avatar={
          <Avatar
            alt="Author"
            src={project.org_avatar}
          >
            {getInitials(project.org_name)}
          </Avatar>
        }
        className={classes.header}
        disableTypography
        subheader={
          <Typography variant="body2">
            by{' '}
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/profile/1/timeline"
              variant="h6"
            >
              {project.org_name} {project.city}
            </Link>{' '}
            | {moment(project.createdAt).fromNow()}
          </Typography>

          
        }
        // action={
        //   <Link
        //       color="textPrimary"
        //       component={RouterLink}
        //       to={"/createevent/"+project.id}
        //       variant="h6"
        //     >
        //       <IconButton aria-label="settings">
        //     <EditIcon />
        //   </IconButton>
        //     </Link>
          
        // }
        title={
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/projects/1/overview"
            variant="h5"
          >
            {project.eventname}
          </Link>
        }
      />
      <CardContent className={classes.content}>
        { <div className={classes.description}>
          {/* <Typography
            colo="textSecondary"
            variant="subtitle2"
          >
           {project.description ? project.description : "We are looking for experienced Developers and Product Designers to come aboard and help us build succesful businesses through softare." }
          </Typography> */}
        </div> }
        <div className={classes.tags}>
            <Label
              color={colors.deepPurple[900]}
              key={project.other_dep_name}
            >
              {project.other_dep_name}
            </Label>
            <Label
              color={colors.deepOrange[600]}
              key={project.other_spl_name}
            >
              {project.other_spl_name}
            </Label>
        </div>
        {/* <div className={classes.tags}>
          {project.tags.map(tag => (
            <Label
              color={tag.color}
              key={tag.text}
            >
              {tag.text}
            </Label>
          ))}
        </div> */}
        
        <div className={classes.details}>
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={3}
          >
             <Grid item xs={12}>
                <Typography variant="body2">Venue</Typography>
                  <Typography variant="h5">{project.location_name}</Typography>
                  <Typography variant="body1">{project.address}</Typography>
                  <Typography variant="body1">{project.city}</Typography>

            </Grid>
             <Grid item >
            <Typography variant="body2" >Event Date</Typography>
             <Typography variant="h6" >{moment(project.start_date).format('DD MMM YYYY')}{' - '}{moment(project.end_date).format('DD MMM YYYY')}</Typography>
            </Grid>
            <Divider />
            <Grid container item alignItems="center"
              justify="space-between" >
                <Grid item>

                <Button color="primary" size="small" variant="outlined">View Booking</Button>
                </Grid>
                <Grid item>
                <Button color="primary" size="small" variant="outlined">Edit</Button>

                </Grid>
                
            </Grid>
           </Grid>
           
            
           
        </div>
      </CardContent>
    </Card>
  );
};

ProjectCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired
};

export default ProjectCard;
