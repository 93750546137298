import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Chip,
  Collapse,
  Divider,
  Drawer,
  Avatar,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  RadioGroup,
  Slider,
  TextField,
  Paper,
  Typography,
  Grid
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactQuill from 'react-quill'; // ES6
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/AddOutlined';

import 'react-quill/dist/quill.snow.css'; // ES6
import moment from 'moment';
import { Label,  TableEditBar,GenericMoreButton } from '../../../../components';

import {

  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { NetworkContext } from '../../../../context/NetworkContext';
import { FilePond, registerPlugin } from "react-filepond"
import "filepond/dist/filepond.min.css"
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import Axios from 'axios';
import {AddVariant } from '../../components'
import { Card ,
  CardHeader,CardActions,
  CardContent} from '@material-ui/core';
registerPlugin(FilePondPluginImageCrop,FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  papercontent: {
      marginTop : theme.spacing(2),
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  descriptionfield: {
    width: '100%'
   
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(2, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
   
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  card: {
    maxHeight: "300px",

    height: "300px",
    textAlign: "left",
    boxShadow: "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)"
},
  nodataCont: {
    textAlign: "center",
    padding: "14px",
    fontSize: "18px",
    color: "#bfbfbf",
    fontWeight: 800
},
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    textAlign: 'right',
    padding: theme.spacing(3),
    paddingRight: theme.spacing(5),
    '& > * + *': {
      margin: theme.spacing(0)
    }
  }
}));

const UserDocuments = props => {
  const { open, onClose, onUpdate,usercontent,masters, viewtype, className, ...rest } = props;

  const classes = useStyles();

  const initialValues = {
    productName: '',
    productID:'',
    unit:'',
    brand:'',
    
    locations:'',
    alertqty : '',
    description : '',
  };
  const { sendNetworkRequest } = React.useContext(NetworkContext);
  const [proofmaster, setProofmaster] = useState();
  const [prooftype, setProoftype] = useState();

  const [expandProject, setExpandProject] = useState(true);
  const [expandCustomer, setExpandCustomer] = useState(false);
  const [subcommodity, setSubcommodity] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isadd, setIsadd] = useState(false);

  const [orders, setOrders] = useState([{
    },
    {

    },
    {

    },
    {

    }]);

  const [values, setValues] = useState({
    ...initialValues
  });

  const handleClear = () => {
    setValues({ ...initialValues });
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };
  function dismiss()
  {
    setIsadd(false)
  }
  function addproduct()
  {
    props.onUpdate(values)
  }
  async function updateuser(userobj)
  {
    setIsadd(true)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };
  const [imgCollection, setImgCollection] = useState(props.community ? props.community : '')
  const [adhaarCollection, setAdhaarCollection] = useState(props.aadhaar ? props.aadhaaar : '')
  const [otherCollection, setOtherCollection] = useState(props.other ? props.other : '')

  const onFileChange = (files) => {
      let items = files.map(fileItem => fileItem.file)
     setImgCollection([...imgCollection, items])
  }


  React.useEffect(() => {
    getsubcategory()
 }, []);
 



 

  const handleToggleProject = () => {
    setExpandProject(expandProject => !expandProject);
  };

  const handleToggleCustomer = () => {
    setExpandCustomer(expandCustomer => !expandCustomer);
  };

  const handleSubmit = event => {
    event.preventDefault();
   // onUpdate && onUpdate(values);


   var formData = new FormData()

   for (let img in imgCollection[0]) {
       formData.append('imgCollection', imgCollection[0][img])
   }

   formData.append('folder', 'folder-name')

   axios.post(`http://localhost:7001/uploadimage`, formData, {
    onUploadProgress : progressEvent => {
      alert((progressEvent.loaded /progressEvent.total))
      console.log("uploadprogrss "+ (progressEvent.loaded /progressEvent.total) )
    } 
  }).then(res => {
       alert(JSON.stringify(res.data))
   })
  };

  const handleinputChange = (value) => {
    setValues(values => ({
      ...values,
      ['description']: value
    }));
  }
  const handlefieldChange = type => e => {
    setValues({
      ...values,
      [type]: e.target.value
    });
  }

  async function uploadimage(imgdata, imagetype) {
    // onUpdate && onUpdate(values);
 
    var formData = new FormData()
    formData.append('photo', imgdata)
    
    

   //  for (let img in imgCollection[0]) {
   //      formData.append('photo', imgCollection[0][img])
   //  }
 
 let img_res =   await axios.post('https://api.chennaisrikalikambal.in/uploads', formData, {
  
 })
 
 
//  .then(res => {
        setValues(values => ({
         ...values,
         [imagetype]: img_res.data.url
       }));
//        let url = res.data.path

//        if(url.includes('.jpg'))
//        {
        props.onUpdate(imagetype,img_res.data.url);
      
//       //  props.onUpdate(imagetype, imgdata);
//        }
      

//     }).catch(err => {
//      // what now?
//      alert(JSON.stringify(err));
//  })
   };
   React.useEffect(() => {
    setValues({
      ...props.usercontent
    })
    if(proofmaster)
    {
      proofmaster.forEach( item => {
          if(item.id === props.usercontent.document_type)
          {
            setValues(values => ({
              ...values,
              ['documenttype']: item
            }));
          }
      })
    }
  }, [props.usercontent]);
  async function getsubcategory()
  {
    let subcategory = await sendNetworkRequest('/getproofs', {}, {},false)
   
    setProofmaster(subcategory)
    if(props.usercontent.document_type)
    {
      subcategory.forEach( item => {
          if(item.id === props.usercontent.document_type)
          {
            setValues(values => ({
              ...values,
              ['documenttype']: item
            }));
          }
      })
    }
   // setValues({...values, masterproofs : subcategory});
  }
  const handleoptionchange = type => (event, value) => {
    setValues(values => ({
      ...values,
      [type]: value
    }));
   props.onUpdate([type],value)
   
  }

  const handleAssetChange =  (e) => {
    uploadimage(e.target.files[0],"community")
  }
  const handleaadhaarchange =  (e) => {
    uploadimage(e.target.files[0],'aadhaar')
  }
  const handleOtherChange =  (e) => {
    uploadimage(e.target.files[0],'otherproof')
  }
 
  return (
    <>
    <Grid
      key={props.usercontent.id}
      container
      spacing={3}
    >
      <Grid
        item
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >

      <CardContent className={classes.content}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
      >
     
     <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
          <Card className={classes.card}>
                       <div style={{ position: "relative" }}>
                                 
                                 <i className="fa fa-window-edit" style={{ position: "absolute", right: 10, top: 11, color: "red", zIndex: '12' }}  />
                                                          <input
                                                          style={{ width: "100%", height: "300px", borderRadius: "4px" }}
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    multiple
                                                                    onChange={e => handleAssetChange(e
                                                                    )}

                                                                ></input>
                                                      
                                                            <img src={values.community ? values.community : "/images/addphoto.jpg"}
                                                                style={{ width: "100%", height: "300px", borderRadius: "4px" }} />
                                                     </div> 
                                                     </Card> 
        </Grid> 
      

          <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        {/* <div className="filepond-wrapper">
        <FilePond 
            files={adhaarCollection}
            allowMultiple={true}
            server={null}
            labelIdle={"Upload Aadhaar"}
            instantUpload={false}
            onupdatefiles={(fileItems) =>{
              if(fileItems && fileItems.length > 0)
              {
              uploadimage(fileItems[0].file, "aadhaar")
              }
            }}>
        </FilePond>
    </div> */}

<Card className={classes.card}>
                       <div style={{ position: "relative" }}>
                                 
                                 <i className="fa fa-window-edit" style={{ position: "absolute", right: 10, top: 11, color: "red", zIndex: '12' }}  />
                                                          <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    multiple
                                                                    onChange={e => handleaadhaarchange(e
                                                                    )}
                                                                    style={{ width: "100%", height: "300px", borderRadius: "4px" }} 
                                                                ></input>
                                                      
                                                            <img src={values.aadhaar ? values.aadhaar : "/images/addphoto.jpg"}
                                                                style={{ width: "100%", height: "300px", borderRadius: "4px" }} />
                                                     </div> 
                                                     </Card> 
        </Grid> 
                  
       
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <Autocomplete
                  id="combo-box-demo"
                  options={[
                    {
                      id:3,
                      name: "Other"
                    }
                  ]}
                  margin="dense"
                  fullWidth
                  value={
                    {
                      id:3,
                      name: "Other"
                    }
                  }
                  onChange={handleoptionchange('documenttype')}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label={"Upload ID proof"} fullWidth margin="dense" variant="outlined" />}
                /> 
        <div className="filepond-wrapper">
       <Card className={classes.card}>
                       <div style={{ position: "relative" }}>
                                 
                                 <i className="fa fa-window-edit" style={{ position: "absolute", right: 10, top: 11, color: "red", zIndex: '12' }}  />
                                                          <input
                                                                    type="file"
                                                                    className="custom-file-input"
                                                                    multiple
                                                                    onChange={e => handleOtherChange(e
                                                                    )}
                                                                    style={{ width: "100%", height: "300px", borderRadius: "4px" }} 
                                                                ></input>
                                                      
                                                            <img src={values.otherproof ? values.otherproof : "/images/addphoto.jpg"}
                                                                style={{ width: "100%", height: "300px", borderRadius: "4px" }} />
                                                     </div> 
                                                     </Card> 
    </div>
        </Grid> 
        <Grid item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        spacing={2}>
      <FormControlLabel
              checked={values.isagree ?  true : false}
              value="Active"
              control={<Checkbox color="primary" 
              />}
              onClick={
                (e) =>  {
                  props.onUpdate("isagree", e.target.checked)
                }
              }
              label='I agree to pay 12 paise as registration fee'
              labelPlacement="end"
            />
        </Grid>           
        <Grid item
        lg={12}
        md={12}
        xl={12}
        xs={12}
        spacing={2}>
      <FormControlLabel
              checked={values.isdeclare ?  true : false}
              value="Active"
              control={<Checkbox color="primary" 
              />}
              onClick={
                (e) =>  {
                  props.onUpdate("isdeclare", e.target.checked)
                }
              }
              label='I declare all above said details are true'
              labelPlacement="end"
            />
        </Grid>
      </Grid>
      <Grid
        
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
         </Grid>
      </CardContent>
    </Card>
      
      </Grid>

    </Grid>
    {/* <Grid
        item
        className={classes.papercontent}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
    <CardContent className={classes.content}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
      >
      <Grid
        item
        lg={4}
        md={6}
        xl={4}
        xs={12}
        spacing={2}
      >
        <Autocomplete
                  id="combo-box-demo"
                  options={[
                    "Single",
                    "Combo",
                    "Variable"
                  ]}
                  margin="dense"
                  fullWidth
                  onChange={handleoptionchange('producttype')}
                  value={values.producttype}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label={"Product Type"} fullWidth margin="dense" variant="outlined" />}
                /> 
      </Grid> 
    
     
      </Grid>
      </CardContent>
   
    </Card>
      
      </Grid> */}

      {/* <Grid
        item
        className={classes.papercontent}
        lg={12}
        md={12}
        xl={12}
        xs={12}
      >

<Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader  action={
          <Button size="small" color={'primary'}
            onClick={() => addvariant()}
          variant={'outlined'}>
            Add Variant

          </Button>
        }
        title="Add Variant"
        subheader="Hint for variant"></CardHeader>
        <CardContent className={classes.content}>
            <div className={classes.inner}>
              <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>S no</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Purchase Price(Exc Tax)</TableCell>
                    <TableCell>Purchase Price(Inc Tax)</TableCell>
                    <TableCell>Margin %</TableCell>
                    <TableCell>Selling Price(Exc Tax)</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.slice(0, rowsPerPage).map((order,index) => (
                    <TableRow
                      key={order.id}
                      selected={selectedOrders.indexOf(order.id) !== -1}
                    >
                 
                      <TableCell>
                        {index + 1}
                        
                      </TableCell>
                      <TableCell>{'BB0001-SAW1212'}</TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Purchase Price(Inc Tax)"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Purchase Price(Exc Tax)"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Margin %"
                        />
                      </TableCell>
                      <TableCell>
                      <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            autoComplete="off"
                            id="prefix"
                            name="prefix"
                            placeholder ="Selling Price(Exc Tax)"
                        />
                      </TableCell>
                      <TableCell>

                      <Avatar alt={order.name} src={`http://128.199.83.45/moogambika_dev${order.imageurl}`} />

                        </TableCell>
                    
                      
                     
                      
                     
                      <TableCell align="center">
                      
                        <GenericMoreButton /> 
                      </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {orders.length > 0 ? "" : <Typography className={classes.nodataCont} component={"h6"} >No Records found</Typography>}
               </TableContainer>   
            </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
     
    </Card>
      
      </Grid> 
      {isadd &&  <AddVariant 
        onDismiss={dismiss}
      />   }                 
    */}
    </>
  );
};

UserDocuments.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool.isRequired,
  masters: PropTypes.object
};

export default UserDocuments;
