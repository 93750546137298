import React from 'react';

const eventcreationCtxInitial = {
    eventCtx: {  
        product_categoy: "",
        steps: ['Step1', 'Step2','Step3','Step4'],
        error_message:{ },
        componentnames:[],
        primarycomponent:{},
        department: "",
        currentdate: new Date(),
        speciality: ""
    },
    issingle: "",
    components:[],
    componentnames:[],
    primarycomponent: {},
    masterData: {},
    setEventCtx: () => null
}

export const EventContext = React.createContext(eventcreationCtxInitial);

export const EventConsumer = EventContext.Consumer;

const mapDataToCtx = (apiData, mapper, mappertype) => {
    if(Object.keys(apiData).length === 0) return {};
    eventcreationCtxInitial.eventCtx[mappertype] = mapper(apiData);

    return eventcreationCtxInitial.eventCtx;
}

export const EventProvider = props => {
    console.info('PROPS',props,props.value.mappertype)

    const [ eventCtx, setEventCtx  ] = React.useState(mapDataToCtx(props.value.data, props.value.mapper,props.value.mappertype));
    // on Mount get Data from storage
    // on Unmount store data to storage

    React.useEffect(() => {
    },[eventCtx.masterData])

    return(
        <EventContext.Provider value={{ eventCtx, setEventCtx, masterData: mapDataToCtx(props.value.data, props.value.mapper, props.value.mappertype) }} >
            {props.children}
        </EventContext.Provider>      
    )
}