import React, { useEffect,useContext, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import uuid from 'uuid/v1';
import moment from 'moment';
import  Page  from '../../components/Page';
import {  UserFilter } from '../../components';
import { NetworkContext } from '../../context/NetworkContext';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import { Header, ProductDetails,UserDocuments,UserConformation,Review } from './components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ProductContext, ProductProvider } from '../../context';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

function getSteps() {
  return ['Basic Information', 'Finger Print', 'Documents','Review'];
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
      case 3:
        return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  
  buttonroot: {
    display: 'flex',
    alignItems: 'center',
  },

  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ProductEdit = withRouter(props => {

  const classes = useStyles();
  const [productobj , setProductobj] = useState({});
  const [userid, setUserid] = useState('');
  const [viewtype, setViewtype] = useState(1);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const { productCtx, setProductCtx } = useContext(ProductContext);
  const [basicinfo , setBasicinfo] = useState({
    city:"சென்னை",
    profile_pic:"",
    isfingerprintcheck : "not accept"
  });
  const [showalert , setShowalert] = useState({
    "type":"error",
    "isopen": false,
    "message":"please agree your details"
  })
  const [userinfo , setUserinfo] = useState({})
  const [renew , setRenew] = useState(false)

  const [avatar , setAvatar] = useState("")
  const [avatarobj , setAvatarobj] = useState("")
  const [community , setCommunity] = useState("")
  const [aadhaar , setAadhaar] = useState("")
  const [otherproof , setOtherproof] = useState("")
  const [fingerprint , setFingerprint] = useState("")
  const [prooftype , setProoftype] = useState({})

  const [documentinfo , setDocumentinfo] = useState({});
  const [fingerinfo , setFingerinfo] = useState({});

  const handleNext = async () => {
	
    if(activeStep == steps.length - 1)
    {
     // lert(JSON.stringify(basicinfo))
     if(renew)
     {
      let prodinfo = await sendNetworkRequest('/addrenewal', {}, {member_id : basicinfo.member_id},false)

      props.history.push(
        {
          pathname: '/memberdetail',
          state: { id: prodinfo.user_profile.id,
            year : prodinfo.user_profile.year}
        })
     }else
     {
     updateuserinformation()
     }
     
    }else
    {
      
      if(activeStep == 0)
      {
        if(!basicinfo.mobile || !basicinfo.aadhaar_no)
        {
          let message = "Please enter aadhaar number"
          if(!basicinfo.mobile)
          {
            message = "Please enter mobile number"
          }
          setShowalert(
            {
              ...setShowalert,
              isopen : true,
              type: "error",
              message:message
 
            }
          )
          return;
         
        }else if(basicinfo.mobile.length < 10 || basicinfo.aadhaar_no.length < 12)
        {
          
          let message = "Please enter valid aadhaar number"
          if(basicinfo.mobile.length < 10)
          {
            message = "Please enter valid mobile number"
          }
          setShowalert(
            {
              ...setShowalert,
              isopen : true,
              type: "error",
              message: message
 
            }
          )
          return;
         
        }
      }
      if(activeStep == 1)
      {
        if(basicinfo.isfingerprintcheck == "not accept")
        {
          setShowalert(
            {
              ...setShowalert,
              isopen : true,
              type: "error",
              message: "please select checkbox"
 
            }
          )
          return;
        }
      }
      if(activeStep == 2)
      {

        if(!basicinfo.community || !basicinfo.aadhaar_no)
        {
         setShowalert(
           {
             ...setShowalert,
             isopen : true,
             type: "error",
             message: "please add your documents"

           }
         )
         return;
        }
        if(!basicinfo.isagree || !basicinfo.isdeclare)
        {
         setShowalert(
           {
             ...setShowalert,
             isopen : true,
             type: "error",
             message: "please accept"

           }
         )
         return;
        }
       let  bodycontent = {
          Name:basicinfo.name,
          member_id: basicinfo.member_id  ? basicinfo.member_id : "",
          fatherName : basicinfo.fatherName,
          email: basicinfo.email,
          isedit : basicinfo.isedit ? basicinfo.isedit :  false ,
          mobile : basicinfo.mobile,
          profile_pic : basicinfo.avatar,
          occupation : basicinfo.occupation,
          pincode : basicinfo.pincode,
          documenttype : basicinfo.documenttype ? basicinfo.documenttype.id : null,
          fingerprint : basicinfo.fingerprint,
          aadhaar_no: basicinfo.aadhaar_no,
          dob : basicinfo.dob,
          ward : basicinfo.ward,
          address : basicinfo.address,
          city :basicinfo.city,
          area :basicinfo.area ,
          state: basicinfo.state,
          community: basicinfo.community,
          documenttype : basicinfo.documenttype,
          aadhaar: basicinfo.aadhaar,
          otherproof: basicinfo.otherproof
        }
        setUserinfo(bodycontent)
        if(activeStep == 0)
        {
         // alert(JSON.stringify(bodycontent))
        }
      }
    
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [masters, setMasters] = useState({
    commodity :[]
  });

  const { sendNetworkRequest } = React.useContext(NetworkContext);


  async function fetchmasters()
  {
    let units = await sendNetworkRequest('/api/getunits', {}, {},false)
    
   
  }

  async function updateuserinformation()
  {

    setSuccess(false);
    setLoading(true);
    
    if(!basicinfo.isagree || !basicinfo.isdeclare)
    {
      setShowalert({
        ...showalert,
        isopen: true,
        message: "please accept"
      })
      setSuccess(true);
      setLoading(false);
      return;
    }
  let prodinfo = await sendNetworkRequest('/addmember', {}, userinfo,true)
  setSuccess(true);
  setLoading(false);
  if (prodinfo.statuscode == 200)
   { 
    setShowalert({
      ...showalert,
      isopen: true,
      message:"Member Registered successfully"
    }) 
    if(basicinfo.isedit)
    {
      props.history.push(
        {
          pathname: '/userlists',
          state: { id: prodinfo.user_profile.id }
        })
    }else
    {
      props.history.push(
        {
          pathname: '/memberdetail',
          state: { id: prodinfo.user_profile.id,
			  year : prodinfo.user_profile.year
		 }
        })

		

    }
        
      }
      else
      {
        setShowalert({
          ...showalert,
          isopen: true,
          message: prodinfo.message
        })
      }
     }

  async function updateproductinfo(type,basevalue)
  {
    setBasicinfo({
      ...basicinfo,
      [type]: basevalue
    });


    // let prodinfo = await sendNetworkRequest('/api/updateproduct', {}, productdetails,false)
   //  alert(JSON.stringify(product))

  }
  async function updateavatar(basevalue)
  {
    setAvatar(basevalue);
}
async function updateavatarobj(basevalue)
  {
    setAvatarobj([...avatarobj,basevalue])
}

async function updateprooftype(prooftype)
{
  setProoftype(prooftype)
}
  async function updatedocumentinfo(type,basevalue)
  {

      if(type === "community")
      {
        setCommunity(
          {
            proof_type : 1,
            proof_url : basevalue
          }
          
        );
      }
        
      if(type === "aadhaar")
      {
        setAadhaar( 
        {
          proof_type : 2,
          proof_url : basevalue
        });
      }

      if(type === "other")
      {
        setOtherproof( {
          proof_type : prooftype.id,
          proof_url : basevalue
        }
        );
      }else
      {
        setBasicinfo({
          ...basicinfo,
          [type] : basevalue
        })
      }

  }
  async function updatefingerinfo(basevalue)
  {
   // setFingerprint(basevalue)
    setBasicinfo({
      ...basicinfo,
   //   fingerprint : basevalue
      isfingerprintcheck: basevalue ? "accept" : "not accept"
    })
  }
  async function fetchproductinfo(prodid)
  {
    let prodinfo = await sendNetworkRequest('/fetchproductinfo', {}, {product_id : prodid},false)

    
    let languages = [];
    if(prodinfo.istamil)
    {
      languages.push('Tamil')
    }

    if(prodinfo.isenglish)
    {
      languages.push('English')
    }
    if(prodinfo.istelugu)
    {
      //languages.push('Telugu')
    }
    if(prodinfo.istamil && prodinfo.isenglish && prodinfo.isTelugu)
    {
      languages.push('All')
    }
    let producttypeobj ={ 
      value : 1,
      label : "Live"
    }
    if(prodinfo.prod_type)
    {
      if(prodinfo.prod_type == 2)
        {
          producttypeobj ={ 
            value : 2,
            label : "Rate"
          }
        }
    }
    let commodity ={}
    let subcommodity ={}
    if(prodinfo.commodity)
    {
      if(prodinfo.commodity.parent_commodity)
      {
        subcommodity = {
          commodityID : prodinfo.commodity.commodityID,
          label : prodinfo.commodity.name
        }
        commodity = {
          value : prodinfo.commodity.parent_commodity.commodityID,
          label : prodinfo.commodity.parent_commodity.name
        }
      }else{
        commodity = {
          value : prodinfo.commodity.commodityID,
          label : prodinfo.commodity.name
        }
      }
    }
   //alert(JSON.stringify(prodinfo))
    setProductobj({
      product_id : prodinfo.productID,
      languages,
      producttype : producttypeobj,
      commodity,
      subcommodity,
      nameenglish: prodinfo.productname,
      nametamil: prodinfo.product_name_tamil,
      nametelugu: prodinfo.product_name_telugu,
      qty : prodinfo.qty,
      imageurl : prodinfo.image
    })
  }

  async function getmemberinfo(userid,isedit, isrenew = false)
  {
    let prodinfo = await sendNetworkRequest('/getmemberinfo', {}, {reg_no : userid},false)
   // setInvoice(prodinfo.members);
    let community_obj = {};
    let aadhaaar_obj ={};
    let other_obj  = {}
   let memberinfo = prodinfo.members
   if(prodinfo.members.Member_certificates && prodinfo.members.Member_certificates.length > 0)
   {
      setCommunity(prodinfo.members.Member_certificates[0])

      prodinfo.members.Member_certificates.forEach(doc => {

          if(doc.proof_type == 1)
          {
            community_obj = doc
          }
          else if(doc.proof_type == 2)
          {
            aadhaaar_obj = doc
          }
         else 
          {
            other_obj = doc
          }
      })


      
   }
 let age = ""
   if(memberinfo.dob)
   {
     let dob = memberinfo.dob
     var today = new Date();
   var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
   var age_now = today.getFullYear() - birthDate.getFullYear();
   var m = today.getMonth() - birthDate.getMonth();
   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
   {
       age_now--;
   }
   console.log(age_now);
   age = age_now;
   }
   setBasicinfo({
      member_id : memberinfo.id,
      Name : memberinfo.Name,
      name : memberinfo.Name,
      fatherName : memberinfo.fatherName,
      avatar : memberinfo.profile_pic,
      email : memberinfo.email,
      mobile : memberinfo.mobile,
      dob: memberinfo.dob,
      isedit : isedit,
      occupation: memberinfo.occupation,
      address : memberinfo.address,
      area : memberinfo.area,
      city : memberinfo.city,
      state : memberinfo.state,
      age,
      aadhaar_no: memberinfo.aadhaar,
      pincode : memberinfo.pincode,
      community : community_obj.proof_url,
    aadhaar : aadhaaar_obj.proof_url,
    otherproof:other_obj.proof_url,
    document_type: other_obj.proof_type
    })
    setRenew(isrenew)
  }

   
    fetchmasters()
    useEffect(() => {
      if(props.location.state)
      {
       const { id,isedit,isrenew } = props.location.state
       getmemberinfo(id,isedit,isrenew)
      }
    
  }, []);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowalert({
      ...showalert,
      isopen: false
    })
  };
  return (
    <ProductProvider value={{data : {},mapper : {},mappertype: "masterData"}}>
    <Page
      className={classes.root}
      title="Commodity"
    >
      {/* <Header title={'Add Product'}/> */}
      <div >
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
  <Snackbar open={showalert.isopen} onClose={handleClose} autoHideDuration={2000} >
  <Alert  severity={showalert.type} onClose={handleClose}>
            {showalert.message}
  </Alert>
</Snackbar>
      <LinearProgress variant="determinate" value={activeStep * 33} />

      <div>
        {activeStep === steps.length ? (
          <div>
             <Alert severity="success">User Created Successfully!</Alert>
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </div>
        ) : (
          <div>

              {activeStep === 0 ? <ProductDetails title={'Add Product'} updateavatar={updateavatar} updateavatarobj={updateavatarobj} avatarobj={avatarobj} usercontent={basicinfo}  masters={masters} viewtype={viewtype} onUpdate={updateproductinfo}/>     : null }       
              {activeStep === 1 ? <UserConformation title={'Add Product'} usercontent={productobj} masters={masters} viewtype={viewtype} onUpdate={updatefingerinfo}/>     : null }      
              {activeStep === 2 ? <UserDocuments title={'Add Product'} usercontent={basicinfo} masters={masters} viewtype={viewtype}  onUpdatetype={updateprooftype}  onUpdate={updateproductinfo}/>     : null }  
              {activeStep === 3 ? <Review invoice={{}} title={'Add Product'} usercontent={userinfo} masters={masters} viewtype={viewtype} onUpdate3={updateproductinfo}/>     : null } 
                     <div className={classes.buttonroot}>
                     <div className={classes.wrapper}>

              
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              </div>
              <div className={classes.wrapper}>
              <Button variant="contained" color="primary"
                        className={buttonClassname}
 
                        disabled={loading}
              onClick={handleNext}>
                {activeStep === steps.length - 1 ? renew ? 'Renew' : 'Submit' : 'Next'}
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
     {/* <ProductDetails title={'Add Product'} usercontent={productobj} masters={masters} viewtype={viewtype} onUpdate={updateproductinfo}/>   */}
     
    </Page>
    </ProductProvider>
  );
});

export default ProductEdit;
